<template>
  <div id="about">
    <div>
    <br>
    <div class="social"><a href="https://twitter.com/crypto_basehead"><p class="foot-logo"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 48 48">
<path fill="#03a9f4" d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"></path><path fill="#fff" d="M36,17.12c-0.882,0.391-1.999,0.758-3,0.88c1.018-0.604,2.633-1.862,3-3	c-0.951,0.559-2.671,1.156-3.793,1.372C29.789,13.808,24,14.755,24,20v2c-4,0-7.9-3.047-10.327-6c-2.254,3.807,1.858,6.689,2.327,7	c-0.807-0.025-2.335-0.641-3-1c0,0.016,0,0.036,0,0.057c0,2.367,1.661,3.974,3.912,4.422C16.501,26.592,16,27,14.072,27	c0.626,1.935,3.773,2.958,5.928,3c-2.617,2.029-7.126,2.079-8,1.977c8.989,5.289,22.669,0.513,21.982-12.477	C34.95,18.818,35.342,18.104,36,17.12"></path>
</svg></p></a>
<a href="https://t.me/+-YHq3Z4z9BdjYTI5"><p class="foot-logo"><svg  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 48 48">
<path fill="#29b6f6" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"></path><path fill="#fff" d="M33.95,15l-3.746,19.126c0,0-0.161,0.874-1.245,0.874c-0.576,0-0.873-0.274-0.873-0.274l-8.114-6.733 l-3.97-2.001l-5.095-1.355c0,0-0.907-0.262-0.907-1.012c0-0.625,0.933-0.923,0.933-0.923l21.316-8.468 c-0.001-0.001,0.651-0.235,1.126-0.234C33.667,14,34,14.125,34,14.5C34,14.75,33.95,15,33.95,15z"></path><path fill="#b0bec5" d="M23,30.505l-3.426,3.374c0,0-0.149,0.115-0.348,0.12c-0.069,0.002-0.143-0.009-0.219-0.043 l0.964-5.965L23,30.505z"></path><path fill="#cfd8dc" d="M29.897,18.196c-0.169-0.22-0.481-0.26-0.701-0.093L16,26c0,0,2.106,5.892,2.427,6.912 c0.322,1.021,0.58,1.045,0.58,1.045l0.964-5.965l9.832-9.096C30.023,18.729,30.064,18.416,29.897,18.196z"></path>
</svg></p></a>
</div>
        <div class="about"><h2>About BaseHead Solana</h2></div>
    <div>
      <p>BaseHead was created for those who are deep into the crypto space. It's kind of like going into that place of no return. We are basers; we roam the streets, searching for that next fix (memecoin). The BaseHead high is like no other. We are working on making BaseHead come to life. BaseHead hates other meme coins, especially rug pull meme coins.
      </p>
      <p><button @click="copyText"  @touchstart="copyText" >Contract Address: 7bm6Y8ry5Go2dnaEc7StNWvKSSvZKazNndBaPc4tpump  </button></p> 
      <br>
    </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'AboutVue',
  methods: {
    copyText() {
      // Specify the text you want to copy
      const textToCopy = '7bm6Y8ry5Go2dnaEc7StNWvKSSvZKazNndBaPc4tpump';

      // Create a temporary textarea element
      const textarea = document.createElement('textarea');
      
      // Set the value of the textarea to the text to copy
      textarea.value = textToCopy;

      // Append the textarea to the DOM
      document.body.appendChild(textarea);

      // Select the text inside the textarea
      textarea.select();

      // Copy the selected text to the clipboard
      document.execCommand('copy');

      // Remove the textarea from the DOM
      document.body.removeChild(textarea);

      // Optionally, provide feedback to the user
      alert('Contract Copied: 7bm6Y8ry5Go2dnaEc7StNWvKSSvZKazNndBaPc4tpump');
    }
  }
};
</script>



<style>

.social{
  justify-content: center; 
  align-items: center;
  display: flex ; 
}

button {
      z-index: 2;
      position: relative;
      /* Set background color */
      background-color: #4CAF50; /* Green */
      
      /* Set text color */
      color: white;
      
      /* Set padding */
      padding: 10px 20px;
      
      /* Set border */
      border: none;
      
      /* Set border radius */
      border-radius: 5px;
      
      /* Set cursor on hover */
      cursor: pointer;
      
      /* Add hover effect */
      transition: background-color 0.3s ease;
    }

    /* Change background color on hover */
    button:hover {
      background-color: #45a049; /* Darker green */
    }

.about{
    color:white; 
    text-align: center ; 
}
</style>