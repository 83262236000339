<template>
    <div>
        <canvas ref="gameCanvas"></canvas>
        <div id="broken-glass-effect" class="glass-effect" v-if="glassEffectVisible"></div>
        <button v-if="gameOver" @click="restartGame" class="restart-button">Play Again</button>
        <button v-if="!gameStarted" @click="startGame" class="start-button">Start Game</button>
    </div>
</template>

<script>
import confetti from 'canvas-confetti';

export default {
    name: "GameVue",
    data() {
        return {
            gameOver: false,
            gameStarted: false,
            score: 0,
            timeRemaining: 60, // Game duration in seconds
            glassEffectVisible: false, // Controls the glass effect visibility
            shotsRemaining: 25, // Limit shots to 25
            powerUpTargets: [], // Additional targets for power-ups
            celebrationActive: false, // To control the celebration state
            mouseX: 0, // Initialize mouse X position
            mouseY: 0, // Initialize mouse Y position
            scopeSize: 50, // Dynamic scope size
            lastShotTime: 0, // Track last shot time to prevent rapid fire
        };
    },
    mounted() {
        this.initializeCanvas();
    },
    methods: {
        initializeCanvas() {
            const canvas = this.$refs.gameCanvas;
            const ctx = canvas.getContext("2d");

            // Set the canvas size dynamically
            const resizeCanvas = () => {
                const aspectRatio = 16 / 9; // Maintain an aspect ratio
                const width = window.innerWidth;
                const height = width / aspectRatio;

                canvas.width = width;
                canvas.height = Math.min(height, window.innerHeight); // Limit height to fit within the screen

                // Adjust scope size for smaller screens
                this.scopeSize = canvas.width < 600 ? 30 : 50; // Smaller scope size for small screens
            };
            window.addEventListener("resize", resizeCanvas);
            resizeCanvas();

            // Adjust element sizes dynamically
            const adjustSizes = () => {
                this.targetRadius = canvas.width / 30; // Scale target size
                this.fontSize = Math.max(16, canvas.width / 50); // Scale font size
            };
            adjustSizes();

            // Support for touch input
            const handleTouchMove = (event) => {
                const rect = canvas.getBoundingClientRect();
                const touch = event.touches[0];
                this.mouseX = touch.clientX - rect.left;
                this.mouseY = touch.clientY - rect.top;
            };

            const handleTouchStart = (event) => {
                const rect = canvas.getBoundingClientRect();
                const touch = event.touches[0];
                this.mouseX = touch.clientX - rect.left;
                this.mouseY = touch.clientY - rect.top;
                this.throttledShoot();
            };

            canvas.addEventListener("touchmove", handleTouchMove);
            canvas.addEventListener("touchstart", handleTouchStart);

            // Initial instructions
            ctx.fillStyle = "white";
            ctx.font = `${this.fontSize}px Arial`;
            ctx.textAlign = "center";
            ctx.fillText(
                "Tap targets to shoot. Green/Blue reload bullets.",
                canvas.width / 2,
                canvas.height / 2 - 30
            );
            ctx.fillText(
                "Reach 50 kills to win!",
                canvas.width / 2,
                canvas.height / 2 + 30
            );
        },

        startGame() {
            this.gameStarted = true;
            this.initializeGame();
        },

        initializeGame() {
            const canvas = this.$refs.gameCanvas;
            const ctx = canvas.getContext("2d");

            // Clear existing event listeners
            if (this.mouseMoveListener) canvas.removeEventListener("mousemove", this.mouseMoveListener);
            if (this.clickListener) canvas.removeEventListener("click", this.clickListener);

            // Set the canvas size dynamically
            const resizeCanvas = () => {
                const aspectRatio = 16 / 9; // Maintain an aspect ratio
                const width = window.innerWidth;
                const height = width / aspectRatio;

                canvas.width = width;
                canvas.height = Math.min(height, window.innerHeight);

                // Adjust scope size for smaller screens
                this.scopeSize = canvas.width < 600 ? 30 : 50; // Smaller scope size for small screens
            };
            window.addEventListener("resize", resizeCanvas);
            resizeCanvas();

            // Load sounds
            const gunShotSound = new Audio(require('@/assets/gunshot.mp3'));
            const targetHitSound = new Audio(require('@/assets/target-hit.mp3'));

            // Load target images and splatter image
            const targetImages = [
                require('@/assets/target1.png'),
                require('@/assets/target2.png'),
                require('@/assets/target3.png'),
                require('@/assets/target4.png'),
                require('@/assets/target5.png'),
                require('@/assets/target6.png'),
                require('@/assets/target7.png'),
                require('@/assets/target8.png'),
                require('@/assets/target9.png'),
                require('@/assets/target10.png'),
                require('@/assets/target11.png'),
                require('@/assets/target12.png'),
                require('@/assets/target13.png'),
                require('@/assets/target14.png'),
                require('@/assets/target15.png'),
                require('@/assets/target16.png'),
                require('@/assets/target17.png'),
                require('@/assets/target18.png'),
            ];
            const splatterImage = new Image();
            splatterImage.src = require('@/assets/splatter.png');

            let currentTargetImage = new Image();
            currentTargetImage.src = targetImages[Math.floor(Math.random() * targetImages.length)];

            // Load background image
            const backgroundImage = new Image();
            backgroundImage.src = require('@/assets/miami_vice_background.png');

            // Game variables
            const target = {
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                radius: this.targetRadius || 50, // Dynamically sized
                speedX: (Math.random() - 0.5) * 10,
                speedY: (Math.random() - 0.5) * 10,
                splatter: false,
            };

            const createPowerUpTarget = () => {
                return {
                    x: Math.random() * canvas.width,
                    y: Math.random() * canvas.height,
                    radius: this.targetRadius || 30,
                    bullets: Math.random() > 0.5 ? 2 : 4, // Green = +2, Blue = +4
                    color: Math.random() > 0.5 ? "green" : "blue",
                };
            };

            this.powerUpTargets = [createPowerUpTarget(), createPowerUpTarget()];

            const drawBackground = () => {
                ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
            };

            const drawTarget = () => {
                if (target.splatter) {
                    ctx.drawImage(
                        splatterImage,
                        target.x - target.radius,
                        target.y - target.radius,
                        target.radius * 2.5,
                        target.radius * 2.5
                    );
                } else {
                    ctx.drawImage(
                        currentTargetImage,
                        target.x - target.radius,
                        target.y - target.radius,
                        target.radius * 2.5,
                        target.radius * 2.5
                    );
                }
            };

            const drawPowerUpTargets = () => {
                this.powerUpTargets.forEach((powerUp) => {
                    ctx.beginPath();
                    ctx.arc(powerUp.x, powerUp.y, powerUp.radius, 0, Math.PI * 2);
                    ctx.fillStyle = powerUp.color;
                    ctx.fill();
                    ctx.closePath();
                });
            };

            const drawScope = (mouseX, mouseY) => {
                ctx.beginPath();
                ctx.strokeStyle = "white";
                ctx.lineWidth = 2;

                ctx.arc(mouseX, mouseY, this.scopeSize, 0, Math.PI * 2);
                ctx.stroke();

                ctx.moveTo(mouseX - this.scopeSize * 1.2, mouseY);
                ctx.lineTo(mouseX + this.scopeSize * 1.2, mouseY);
                ctx.moveTo(mouseX, mouseY - this.scopeSize * 1.2);
                ctx.lineTo(mouseX, mouseY + this.scopeSize * 1.2);
                ctx.stroke();

                ctx.closePath();
            };

            const updateTarget = () => {
                if (!target.splatter) {
                    target.x += target.speedX;
                    target.y += target.speedY;

                    if (target.x - target.radius < 0 || target.x + target.radius > canvas.width) {
                        target.speedX *= -1;
                    }
                    if (target.y - target.radius < 0 || target.y + target.radius > canvas.height) {
                        target.speedY *= -1;
                    }
                }
            };

            const updatePowerUpTargets = () => {
                this.powerUpTargets.forEach((powerUp) => {
                    powerUp.x += (Math.random() - 0.5) * 4;
                    powerUp.y += (Math.random() - 0.5) * 4;

                    if (powerUp.x - powerUp.radius < 0 || powerUp.x + powerUp.radius > canvas.width) {
                        powerUp.x = Math.random() * canvas.width;
                    }
                    if (powerUp.y - powerUp.radius < 0 || powerUp.y + powerUp.radius > canvas.height) {
                        powerUp.y = Math.random() * canvas.height;
                    }
                });
            };

            this.mouseMoveListener = (event) => {
                const rect = canvas.getBoundingClientRect();
                this.mouseX = event.clientX - rect.left;
                this.mouseY = event.clientY - rect.top;
            };

            this.clickListener = () => {
                this.throttledShoot();
            };

            this.throttledShoot = () => {
                const now = Date.now();
                if (now - this.lastShotTime < 300) return; // Prevent rapid fire by throttling to 300ms
                this.lastShotTime = now;

                if (this.gameOver || this.celebrationActive) return;

                if (this.shotsRemaining <= 0) {
                    this.gameOver = true;
                    ctx.fillStyle = "red";
                    ctx.font = "40px Arial";
                    ctx.textAlign = "center";
                    ctx.fillText("Out of Bullets", canvas.width / 2, canvas.height / 2);
                    return;
                }

                this.shotsRemaining--;
                gunShotSound.currentTime = 0;
                gunShotSound.play();

                const distance = Math.sqrt(
                    (this.mouseX - target.x) ** 2 + (this.mouseY - target.y) ** 2
                );

                this.triggerGlassEffect();

                if (distance <= target.radius) {
                    this.score++;

                    target.splatter = true;

                    targetHitSound.currentTime = 0;
                    targetHitSound.play();

                    setTimeout(() => {
                        target.x = Math.random() * canvas.width;
                        target.y = Math.random() * canvas.height;
                        target.splatter = false;
                        currentTargetImage.src = targetImages[Math.floor(Math.random() * targetImages.length)];
                    }, 500);

                    if (this.score >= 50) {
                        this.celebrationActive = true;
                        confetti({
                            particleCount: 200,
                            spread: 70,
                            origin: { y: 0.6 },
                        });
                        setTimeout(() => {
                            this.gameOver = true;
                        }, 3000);
                    }
                }

                this.powerUpTargets.forEach((powerUp, index) => {
                    const powerUpDistance = Math.sqrt(
                        (this.mouseX - powerUp.x) ** 2 + (this.mouseY - powerUp.y) ** 2
                    );
                    if (powerUpDistance <= powerUp.radius) {
                        this.shotsRemaining = Math.min(this.shotsRemaining + powerUp.bullets, 25);
                        this.powerUpTargets.splice(index, 1);
                        this.powerUpTargets.push(createPowerUpTarget());
                    }
                });
            };

            canvas.addEventListener("mousemove", this.mouseMoveListener);
            canvas.addEventListener("click", this.clickListener);

            const gameLoop = () => {
                if (this.gameOver) return;

                ctx.clearRect(0, 0, canvas.width, canvas.height);
                drawBackground();
                drawTarget();
                drawPowerUpTargets();
                updateTarget();
                updatePowerUpTargets();
                drawScope(this.mouseX, this.mouseY);

                ctx.fillStyle = "white";
                ctx.font = "20px Arial";
                ctx.fillText(`Score: ${this.score}`, 10, 30);
                ctx.fillText(`Shots Left: ${this.shotsRemaining}`, 10, 60);
                ctx.fillText(`Time: ${this.timeRemaining}s`, canvas.width - 120, 30);

                requestAnimationFrame(gameLoop);
            };

            const timer = setInterval(() => {
                if (this.timeRemaining > 0 && !this.celebrationActive) {
                    this.timeRemaining--;
                } else {
                    clearInterval(timer);
                    if (!this.celebrationActive) {
                        this.gameOver = true;
                        ctx.fillStyle = "white";
                        ctx.font = "40px Arial";
                        ctx.fillText("Game Over!", canvas.width / 2, canvas.height / 2);
                    }
                }
            }, 1000);

            gameLoop();

            this.cleanup = () => {
                window.removeEventListener("resize", resizeCanvas);
                clearInterval(timer);
            };
        },

        triggerGlassEffect() {
            this.glassEffectVisible = true;
            setTimeout(() => {
                this.glassEffectVisible = false;
            }, 300);
        },

        restartGame() {
            this.gameOver = false;
            this.gameStarted = false;
            this.score = 0;
            this.shotsRemaining = 25;
            this.timeRemaining = 60;
            this.celebrationActive = false;
            this.initializeCanvas();
        },
    },
    beforeUnmount() {
        if (this.cleanup) {
            this.cleanup();
        }
    },
};
</script>

<style>
canvas {
    display: block;
    margin: 0 auto;
    width: 100%;
}

.glass-effect {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: url('@/assets/broken_glass.png') center/cover no-repeat;
    pointer-events: none;
    opacity: 0.6;
    animation: fadeOut 0.3s ease forwards;
}

@keyframes fadeOut {
    from {
        opacity: 0.6;
    }
    to {
        opacity: 0;
    }
}

.restart-button {
    bottom: -200%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    font-size: 20px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
}

.restart-button:hover {
    background-color: #0056b3;
}

.start-button {
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    font-size: 20px;
    color: white;
    background-color: #28a745;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
}

.start-button:hover {
    background-color: #218838;
}
</style>
