<template>
  <div id="token">
    <div>
      <br>
      <br>
    </div>
    <div class="token">
    <div class="border-window">
    <div class="content">
      <h2>Tokenomics</h2>
       <p>Token Name: Base-Head Solana</p>
       <p>Token Supply: pump.fun</p>
      <p>DexTools</p>
       <p><a href="">
<img src="../assets/DEXTools_logo.png"></a></p>
  </div>
  </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'TokenVue'
};
</script>

<style scoped>

h2{
    text-align: center ; 
}

.token{
    margin-top: 0 ; 
    position: relative ; 
    height:550px ; 
    width: 100% ; 
    background-color:#06063a  ; 
    background-image: url("../assets/basers.png");
    background-repeat:repeat; 
}

.border-window {
  position: absolute;
  width:300px; 
  height: 300px; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(0, 0, 0, 0.1); /* Transparent black border */
  padding: 20px;
  background-color: rgba(136, 153, 252, 0.2); /* Semi-transparent white background */
  z-index: 1000;
}

.content {
  color: #ffffff; /* Text color */
}

</style>